
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";

import logo1 from '../../assets/images/logo1.png'
import waves from '../../assets/images/svg-animation-waves_red.svg'
import tablet_cizgi_sol from '../../assets/images/tablet_cizgi_sol.png'




function Tablet2Play() {
  var AudioStreamMeter = require('audio-stream-meter');
  const navigate = useNavigate();
  const [user_code, setuser_code] = useState("");
  const [user_name, setuser_name] = useState("");


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [point, setPoint] = useState(0);


  const [decibel, setDecibel] = useState(0);
  const [decibel_div, setdecibel_div] = useState(0);
  const [point_div, setpoint_div] = useState(0);


  const [cou, setcou] = useState(300);
  const [started, setstarted] = useState(false);

  useEffect(() => {

    const user_code = localStorage.getItem('user2_code');
    setuser_code(user_code)

    get(child(dbRef, "users/"+user_code+"/name")).then((snapshot) => {
      if (snapshot.exists()) {
        let isim= snapshot.val().split(" ");
        setuser_name(isim[0])
        
      } 
    })


    setstarted(true)
    listenStatus()


    const unsubscribe =onValue(ref(db, 'status'), (snapshot) => {
      
      const data = snapshot.val();

      if(data === "finished"){

        bitir()


      }else if(data === "waiting"){
        navigate('/tablet2/wait')
      }else if(data === ""){
        navigate('/tablet2')
      }else{

      }
      
    });

    return () => {
      console.log("stoped play");
      unsubscribe()
    };
    
  },[]);

  const listenStatus = async () => {
    
    startListening()
    
  }


  const bitir = async () => {

    var _pp = 0
    var _sure = 0

    await get(child(dbRef, "user2_puan")).then((snapshot) => {
          _pp = snapshot.val()
    })
    await get(child(dbRef, "sure")).then((snapshot) => {
        _sure = snapshot.val()
    })

    const user_code = localStorage.getItem('user2_code');

    set(ref(db, '/users/'+user_code+'/puan'),_pp);
    set(ref(db, '/users/'+user_code+'/sure'),_sure);



    navigate('/tablet2/finish')

}



  useEffect(() => {
    if(started){

      const interval = setInterval(() => {
        setcou(cou -1);

        set(ref(db, '/sure'),Math.round(cou/10));

        lookDecibel()
      }, 100);

      if(cou === 0){
        clearInterval(interval);
        set(ref(db, '/status'),"finished");
        set(ref(db, '/ekran_status'),"finished");
      }
      return () => clearInterval(interval);
    }

  }, [cou,started]);



  const lookDecibel = () => {

    if(decibel <100){
      if(point>0){
        setPoint(point - .1)
      }else{
        setPoint(0)
      }
    }

    if(decibel >100 && decibel < 150){
      setPoint(point + .1)
    }
    if(decibel >=150 && decibel < 200){
      setPoint(point + .15)
    }
    if(decibel >=200 && decibel < 250){
      setPoint(point + .2)
    }
    if(decibel >=250 ){
      setPoint(point + .25)
    }
    

    setdecibel_div(decibel*2.8)
  } 



  const startListening = async () => {

    var stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
    });
    

    var audioContext = new AudioContext();
    

    var mediaStream = audioContext.createMediaStreamSource(stream);

    var meter = AudioStreamMeter.audioStreamProcessor(audioContext, function() {
        setDecibel(Math.round(meter.volume*1000));
    });
      
    mediaStream.connect(meter);
    stream.onended = meter.close.bind(meter);

  }

  useEffect(() => {
    setpoint_div(point*10)
    set(ref(db, '/user2_puan'),point);

    if(point >= 100){
      set(ref(db, '/status'),"finished");
      set(ref(db, '/ekran_status' ),"finished");

    }

  },[point]);


    return (
      <div className='p-10 h-screen relative'>
                  <img src={logo1} alt='' className='w-60 mt-10 m-auto ' />

                  <div className='text-center font-bold text-2xl mt-8'>KALAN SÜRE</div>
                  <div className='text-center font-bold text-4xl mt-1 mb-10'>00:{Math.round(cou/10) < 10 ? "0":""}{Math.round(cou/10)}</div>

                  <img src={tablet_cizgi_sol} className='absolute z-20 ' width={20.5} style={{bottom:70, left:138}} alt=''  />
                  <img src={tablet_cizgi_sol} className='absolute rotate-180 z-20 ' width={20.5} style={{bottom:70, right:138}} alt=''  />

                  <div className='absolute z-30 text-center left-0 right-0' style={{top:370}}>
                    <span className=' text-white px-10 py-2 font-bold text-3xl bg_kirmizi'>{user_name}</span>
                  </div>

                  <div className='bg-slate-300 absolute top-16 bottom-16 left-10 w-16 rounded-3xl overflow-hidden'>
                    <div className='bg_kirmizi w-full transition-all duration-0 bottom-0 absolute ' style={{height:decibel_div}}></div>
                  </div>

                  <div className='bg-slate-300 absolute top-96 bottom-16   rounded-3xl overflow-hidden' style={{left:180, right:180}}>
                    <img src={waves} alt='' className='w-full absolute left-0 right-0 z-10 ' style={{bottom:(point*6.5)-100}} />
                    <div className='bg_kirmizi w-full transition-all bottom-0 absolute ' style={{height:point+'%'}}></div>
                    <div className='text-center absolute bottom-10 z-10 text-white font-bold text-8xl left-0 right-0'>%{Math.round(point)}</div>
                  </div>

              
                  <div className='bg-slate-300 absolute top-16 bottom-16 right-10 w-16 rounded-3xl overflow-hidden'>
                    <div className='bg_kirmizi w-full transition-all duration-0 bottom-0 absolute ' style={{height:decibel_div}}></div>
                  </div>


      </div>
    );
}

  export default Tablet2Play;