
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";

import { CountdownCircleTimer } from 'react-countdown-circle-timer'



function Users() {
  var AudioStreamMeter = require('audio-stream-meter');
  const navigate = useNavigate();
  const [user_code, setuser_code] = useState("");
  const [user_name, setuser_name] = useState("");


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [users, setusers] = useState([]);


  const [decibel, setDecibel] = useState(0);
  const [decibel_div, setdecibel_div] = useState(0);
  const [point_div, setpoint_div] = useState(0);


  const [cou, setcou] = useState(300);
  const [started, setstarted] = useState(false);

  useEffect(() => {


      getUsers()
    
  },[]);



  const getUsers = async () => {


    await get(child(dbRef, "users")).then((snapshot) => {
      
      //
      var _t = []
      snapshot.forEach(e => {
        console.log(e.val());
        _t.push(e.val())
      });

      setusers(_t)

    })
  }





    return (
      <div className='p-10 h-screen relative'>

        <table >

        

        {users.map((user, index) => (
          <tr key={index} >
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>{user.puan}</td>
            <td>{user.sure}</td>
          </tr>
        ))}

      </table>

      </div>
    );
}

  export default Users;