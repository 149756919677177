
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";




function TestMe() {
  var AudioStreamMeter = require('audio-stream-meter');
  const navigate = useNavigate();
  const [user_code, setuser_code] = useState("");
  const [user_name, setuser_name] = useState("");


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [point, setPoint] = useState(0);


  const [decibel, setDecibel] = useState(0);
  const [decibel_div, setdecibel_div] = useState(0);
  const [point_div, setpoint_div] = useState(0);


  const [cou, setcou] = useState(300);
  const [started, setstarted] = useState(false);

  useEffect(() => {


    setstarted(true)
    listenStatus()



    
  },[]);

  const listenStatus = async () => {
    
    startListening()
    
  }






  var stream ;


  const startListening = async () => {


    await navigator.mediaDevices.getUserMedia({
      audio: {
        mandatory: {

            googEchoCancellation: false,
            googAutoGainControl: false,
            googAutoGainControl2: false,
            googNoiseSuppression: false,
            googHighpassFilter: false,
            googTypingNoiseDetection: false,
            googAudioMirroring: false,
            echoCancellation: false,

        }
        
      }
        
          
    }).then(function (mediaStream) { 
      stream = mediaStream
    });

    console.log(stream);

    var audioContext = new AudioContext();
    
    var mediaStream = audioContext.createMediaStreamSource(stream);

    console.log(mediaStream);

    var meter = AudioStreamMeter.audioStreamProcessor(audioContext, function() {

      //console.log(meter);
        
        setDecibel(Math.round(meter.volume*1000));
    });
      
    mediaStream.connect(meter);
    stream.onended = meter.close.bind(meter);


  }



    return (
      <div className='p-10 h-screen relative'>

                  
                  <div className='absolute text-3xl z-40 left-2 top-2'>--{decibel}-</div>

                  --v1--


      </div>
    );
}

  export default TestMe;