
import {  useState , useEffect} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";


import logo1 from '../../assets/images/tv_ic_logo.png'


function AnaEkranFinished() {

  const navigate = useNavigate();
  const [user1, setuser1] = useState("");
  const [user1_puan, setuser1_puan] = useState("");
  const [user2, setuser2] = useState("");
  const [user2_puan, setuser2_puan] = useState("");
  const [kazanan, setKazanan] = useState("");
  const [kazanan_renk, setkazanan_renk] = useState("");


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);
  


  useEffect(() => {

    get(child(dbRef, "user1")).then((snapshot) => { setuser1(snapshot.val()) })
    get(child(dbRef, "user1_puan")).then((snapshot) => { setuser1_puan(snapshot.val())})
    get(child(dbRef, "user2")).then((snapshot) => { setuser2(snapshot.val())})
    get(child(dbRef, "user2_puan")).then((snapshot) => { setuser2_puan(snapshot.val())})

    const unsubscribe1 =onValue(ref(db, 'ekran_status'), (snapshot) => {
      if(snapshot.val() === ""){  navigate('/anaekran')  }
    });

    return () => { unsubscribe1(); };


  }, [])

  
  useEffect(() => {

    if(user1 !== "" && user1_puan !== "" && user2 !== "" && user2_puan !== ""){
      
        if(user1_puan > user2_puan){
          get(child(dbRef, "users/"+user1)).then((snapshot) => { 
            let isim= snapshot.val().name.split(" ");
            setKazanan(isim[0])
            setkazanan_renk("mavi")
          })
        }
        if(user1_puan < user2_puan){
          get(child(dbRef, "users/"+user2)).then((snapshot) => { 
            let isim= snapshot.val().name.split(" ");
            setKazanan(isim[0])
            setkazanan_renk("kirmizi")

          })
        }

        if(user1_puan === user2_puan){
          setKazanan("Berabere")
          setkazanan_renk("mavi")

        }

    }

  }, [user1,user1_puan,user2_puan,user2])
  


  return (
    
      <div className="ekran_bg p-10 h-screen  flex flex-col items-center justify-center  ">
        <img src={logo1} alt='' className=' mx-auto ' style={{width:640}} />
        <div  className='text-center font-bold text-5xl mt-12'>OYUN SONA ERDİ.</div>
        <div  className='text-center font-bold text-6xl mt-16'>KAZANAN</div>
        <span className={(kazanan_renk === "mavi" ? "bg_mavi" : "bg_kirmizi" ) + ' text-center font-bold text-6xl mt-8  text-white px-7 py-5'}>{kazanan}</span>
    </div>


  );
}

export default AnaEkranFinished;