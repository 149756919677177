
import {  useState , useEffect} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";


import logo1 from '../../assets/images/logo1.png'


function AnaEkran() {

  const navigate = useNavigate();

  const gotoRegister = async () => {
    navigate('/tablet1/register')
  }

  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);


  useEffect(() => {
    const unsubscribe = onValue(ref(db, 'ekran_status'), (snapshot) => {
      if(snapshot.val() === "started"){ navigate('/anaekran/play') }
    });
  
    return () => { unsubscribe()};
  
  }, [])
  

  return (
    <div className="ekran_giris_bg p-10 h-screen relative flex justify-center ">
    </div>


  );
}

export default AnaEkran;