
import {  useState , useEffect, useRef} from 'react'
import Modal from 'react-modal';
import {showError, showSuccess,date_tr} from '../../components/commonFuncs'
import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import logo1 from '../../assets/images/logo1.png'
import { TbSquare,TbX, TbSquareCheckFilled } from "react-icons/tb";
import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '60vh',

    
  },
};

function Tablet1Register() {
  const navigate = useNavigate();

  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const handleEmail = (e) => { setEmail(e.target.value) }
  const handleName = (e) => { setName(e.target.value) }
  const handlePhone = (e) => { setPhone(e.target.value) }


  const [kosul_1, setkosul_1] = useState(false);
  const [kosul_2, setkosul_2] = useState(false);



  const validateEmail = (em) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(em);
  }


  useEffect(() => {


  },[]);


  const save = async () => {

    var _ready = true;

    if(kosul_1 === false || kosul_2 === false){
      _ready = false
      showError("Lütfen katılım koşulları ve aydınlatma metinini kabul ediniz!")
    }


    if(name.length < 3){
      _ready = false
      showError("Lütfen isminizi giriniz!")
    }

    

    if(!validateEmail(email)) {
      _ready = false
      showError("Lütfen geçerli bir email adresi giriniz!")
    }
      

    if(phone.length < 10){
      _ready = false
      showError("Lütfen telefon numaranızı kontrol ediniz!")
    }



    if(_ready){
      let makeid = makeIOId(12);
  
      await set(ref(db, 'users/' + makeid  ),{
        email: email,
        name: name,
        phone: phone,
        time_created: Date.now()
      });

  
      localStorage.setItem('user1_code',makeid);
  
  
      await set(ref(db, 'user1' ),makeid);
      await set(ref(db, 'user1_status' ),"waiting");
      await set(ref(db, 'status' ),"waiting");
  
      navigate('/tablet1/tutorial')

    }
    


  }

  const makeIOId = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const cikis = () => {
    navigate('/tablet1')
  }


  
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  function openModal1() { setModal1Open(true); }
  function closeModal1() { setModal1Open(false); }
  function openModal2() { setModal2Open(true); }
  function closeModal2() { setModal2Open(false); }



    return (
      <div className='py-10 px-28 relative'>
          <img src={logo1} alt='' className='w-56 mt-10 m-auto ' />

          <button onClick={cikis} className='absolute top-2 right-2 p-2 text-slate-300' >Çıkış</button>

          <div className='text-3xl font-bold mt-20'>Hoş geldin!</div>
          <div className='font-semibold text-lg mt-2'>Formu doldurarak giriş yapabilirsin.</div>

          <div className='font-semibold text-lg mt-10 mb-1' >Adın</div>
          <input type="name" placeholder='Ad, Soyad' value={name} onChange={handleName} className="w-full  text-lg border border_mavi p-4  placeholder:text-slate-500  "  />

          <div className='font-semibold text-lg mt-5 mb-1' >Email Adresin</div>
          <input type="email" placeholder='Email adresi' value={email} onChange={handleEmail} className="w-full text-lg border border_mavi p-4  placeholder:text-slate-500  "  />
        
          <div className='font-semibold text-lg mt-5 mb-1' >Telefon Numaran</div>
          <input type="phone" placeholder='05XX XXX XX XX' value={phone} onChange={handlePhone} className="w-full text-lg border border_mavi p-4  placeholder:text-slate-500  "  />

          <div className='flex items-center space-x-2 mt-5'>
            <button onClick={() => setkosul_1(!kosul_1)}>
              {kosul_1 ? <TbSquareCheckFilled size={30} strokeWidth={1.3} /> : <TbSquare size={30} strokeWidth={1.3} />}
            </button>
            <div className='flex-1'><button onClick={openModal1} className='font-semibold'>Katılım Koşullarını</button> okudum, anladım ve kabul ediyorum.</div>
          </div>

          <div className='flex items-start space-x-2 mt-4'>
            <button onClick={() => setkosul_2(!kosul_2)}>
              {kosul_2 ? <TbSquareCheckFilled size={30} strokeWidth={1.3} /> : <TbSquare size={30} strokeWidth={1.3} />}
            </button>
            <div className='flex-1 text-base'>Kişisel verilerimin işlenmesi ve paylaşılması kapsamında yazılmış <button onClick={openModal2} className='font-semibold'>Aydınlatma Metnini</button> okudum, anladım ve kabul ediyorum.</div>
          </div>

          <div className='mt-8'>
          <button className='bg_kirmizi p-4 w-full text-xl font-semibold text-white' onClick={save}>GİRİŞ YAP</button>  
          </div>

        <Modal isOpen={modal1Open} onRequestClose={closeModal1} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal1}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-semibold'>Kullanım ve Katılım Koşulları</div>
            <div className='mt-2'>
              <div className='mt-2'>1. Kabul Edilme: Bu web arayüzünü kullanarak, aşağıdaki kullanım koşullarını kabul etmiş olursunuz. Lütfen bu koşulları dikkatlice okuyun.</div>
              <div className='mt-2'>2. Oyun Katılım Koşulları ve KVKK Aydınlatma Metni katılımcı tarafından okunmalı ve katılımcı tarafından uygun görüldüğü takdirde işaretlenip/onaylanıp yarışmaya girilmelidir.</div>
              <div className='mt-2'>3. İçerik Kullanımı: Red Bull  SoundClash Desibel Oyunu, iki katılımcının aynı anda katılıp birbirlerine karşı yarıştığı bir eğlence kurgusudur. Arayüz dahilinde bulunan her türlü içerik (metinler, fotoğraflar, videolar vb.) sadece bilgilendirme amacıyla sunulmuştur. Bu içeriklerin ticari amaçlarla kullanılması kesinlikle yasaktır.</div>
              <div className='mt-2'>4. Oyun iki katılımcı tarafından eş zamanlı olarak oynanır. 30 saniye boyunca devam eden oyunda katılımcılar yüksek ses çıkararak ekrandaki hazneyi rakibinden daha fazla doldurmaya çalışır. 30 saniye bitmeden hazneyi tamamen dolduran katılımcı otomatik olarak kazanmış sayılır.</div>
              <div className='mt-2'>5. Fikri Mülkiyet Hakları: Red Bull  SoundClash Desibel Oyunu üzerinde bulunan tüm içerikler, şirketimize veya lisans verenlerimize aittir ve uluslararası fikri mülkiyet kanunlarıyla korunmaktadır. İzinsiz olarak kopyalanması, çoğaltılması veya dağıtılması yasaktır.</div>
              <div className='mt-2'>6. Katılımcılar, oyuna ad, soyad, eposta adresi ve kendi mobil telefon numarası bilgileri ile giriş yapacaktır.</div>
              <div className='mt-2'>7. Oyuna katılım yapılırken verilen bilgilerin tam, eksiksiz ve doğru olarak yazılması gerekir.</div>
              <div className='mt-2'>8. Başkasına ait bilgiler ile ve/veya aynı anda birden fazla cihazdan girişi tespit edilen kullanıcıların ödül hak edişleri olmayacaktır.</div>
              <div className='mt-2'>9. 18 yaş altı katılımcılar oyuna katılamazlar, katılsalar dahi ödül kazanamazlar.</div>
              <div className='mt-2'>10. Kullanıcı Katkıları: Red Bull  SoundClash Desibel Oyunu üzerinde kullanıcılar tarafından sağlanan metin, fotoğraf, video ve diğer içerikler, kullanıcıların sorumluluğundadır. Bu içeriklerin herhangi bir şekilde yasa dışı, uygunsuz veya zararlı olması durumunda, şirketimiz bunları kaldırma veya değiştirme hakkına sahiptir.</div>
              <div className='mt-2'>11. Değişiklikler: Red Bull  SoundClash Desibel Oyunu, bu kullanım koşullarını dilediği zaman değiştirme hakkını saklı tutar. Değişiklikler web sitesinde yayınlandıktan sonra geçerli olacaktır. Lütfen düzenli olarak bu sayfayı kontrol edin.</div>
              <div className='mt-2'>12. İletişim: Herhangi bir soru, öneri veya şikayetiniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.</div>
              <div className='mt-2'>Bu kullanım koşullarını kabul etmediğiniz takdirde, lütfen oyunu kullanmayın. Kullanım koşulları kabul edilmediği takdirde doğacak sorumluluk tarafınıza ait olacaktır. Teşekkür ederiz.</div>
            </div>
        </Modal>
        <Modal isOpen={modal2Open} onRequestClose={closeModal2} style={customStyles} >
            <div className='flex justify-end'><button onClick={closeModal2}><TbX strokeWidth={2.7} size={22} /></button></div>
            <div className='text-2xl font-medium'>Kişisel Verilerin Korunması Kanunu (KVKK) Bildirimi</div>
            <div className='mt-2'>
              <div className='mt-2'>Sayın Katılımcı,</div>
              <div className='mt-2'>NG Event olarak, kişisel verilerinizin gizliliğini önemsiyor ve korunmasına büyük önem veriyoruz. Bu nedenle, Kişisel Verilerin Korunması Kanunu (KVKK) çerçevesinde size gerekli bilgilendirmeyi yapmak istiyoruz:</div>
              <div className='mt-2'>1. Veri Sorumlusu: NG Event, kişisel verilerinizin işlenmesinden sorumlu veri sorumlusudur.</div>
              <div className='mt-2'>2. Kişisel Verilerin İşlenme Amaçları: Sizinle olan etkileşimimizde, fotoğraflarınız ve videolarınız gibi kişisel verileriniz, Red Bull  SoundClash etkinliği kapsamında gerçekleşen saha etkinlikleri için kullanılabilir. Bu verilerin işlenme amacı, etkinlik katılımcılarının deneyimlerini paylaşmak, etkinlik hakkında bilgi vermek ve etkinliği tanıtmak amacıyla kullanılmaktadır.</div>
              <div className='mt-2'>3. Toplanan Kişisel Veriler: Red Bull  SoundClash Desibel Oyunu üzerinde, adınız, fotoğrafınız, videolarınız gibi kişisel verileriniz etkinlikle ilgili paylaşımlar için toplanabilir.</div>
              <div className='mt-2'>4. Kişisel Verilerin Saklanma Süresi: Kişisel verileriniz, işlenme amacının gerektirdiği süre boyunca saklanır ve ardından güvenli bir şekilde silinir veya anonim hale getirilir.</div>
              <div className='mt-2'>5. Kişisel Verilere Erişim ve Güncelleme: KVKK kapsamında size tanınan haklar doğrultusunda, kişisel verilerinizle ilgili taleplerinizi bize iletebilirsiniz. Bu haklarınız arasında, kişisel verilerinize erişme, düzeltilme, silinme veya işleme itiraz etme hakları bulunmaktadır.</div>
              <div className='mt-2'>6. Kişisel verilerin aktarılacağı alıcı grupları: Kişisel verileriniz; KVKK’nın kişisel verilerin aktarılmasına ilişkin hükümleri kapsamında ve talep halinde yurt içindeki; resmi kurum ve kuruluşlara ve iş ortaklarımıza aktarılabilmektedir.</div>
              <div className='mt-2'>7. Açık rıza beyanı: Devam eden pencereye onay vererek ilgili pencerede yer alan hususlarda açık rızanız bulunduğunu kabul etmiş olacaksınız.</div>
              <div className='mt-2'>8. Hukuki Sebep: Firmamızla hukuki ilişkinizin kurulması esnasında ve söz konusu ilişkinin devamı süresince sizlerden, üçüncü kişilerden ve yasal mercilerden olmak kaydıyla internet sitesi, muhtelif sözleşmeler, elektronik posta, başvuru formları gibi araçlar üzerinden, Kurumumuz ile yapılan yazılı veya sözlü iletişim kanalları aracılığıyla sözlü, yazılı veya elektronik ortamda toplanmaktadır. Bu doğrultuda toplanan kişisel verileriniz KVKK’nın 5. ve 8. maddelerinde belirtilen hukuka uygunluk sebeplerine dayanılarak işlenmektedir.</div>
              <div className='mt-2'>KVKK kapsamında kişisel verilerinizin işlenmesi ile ilgili herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.</div>
              <div className='mt-2'>İletişim yetkili mail: damla.lebut@ngevent.com</div>
              <div className='mt-2'>KVKK Sorumlusu: Damla Lebut</div>
              <div className='mt-2'>Kişisel veri işleme faaliyetinin açık rıza şartına dayalı olarak gerçekleştirilmesi halinde, aydınlatma yükümlülüğü ve açık rızanın alınması işlemlerinin ayrı ayrı yerine getirilmesi gerekmektedir.</div>
              <div className='mt-2'>Kanunun 10 uncu maddesinin birinci fıkrasının (ç) bendinde yer alan “hukuki sebep” ten kasıt, aydınlatma yükümlülüğü kapsamında kişisel verilerin Kanunun 5 ve 6 ncı maddelerinde belirtilen işleme şartlarından hangisine dayanılarak işlendiğidir. Aydınlatma yükümlülüğünün yerine getirilmesi esnasında hukuki sebebin açıkça belirtilmesi gerekmektedir.</div>
              <div className='mt-2'>Aydınlatma yükümlülüğü kapsamında, kişisel verilerin aktarılma amacı ve aktarılacak alıcı grupları belirtilmelidir.</div>
              <div className='mt-2'>Aydınlatma yükümlülüğünün yerine getirilmesi, ilgili kişinin talebine bağlı değildir.</div>
              <div className='mt-2'>Aydınlatma yükümlülüğünün yerine getirildiğinin ispatı veri sorumlusuna aittir.</div>
              <div className='mt-2'>Sicile kayıt yükümlülüğünün bulunması durumunda, aydınlatma yükümlülüğü çerçevesinde ilgili kişiye verilecek bilgiler, Sicile açıklanan bilgilerle uyumlu olmalıdır.</div>
              <div className='mt-2'>KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA AÇIK RIZA BEYANI</div>
              <div className='mt-2'>6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVK Kanunu”) ilgili hükümlerine uygun olarak bilginize sunulan Kişisel Verilerin Korunması Kanunu Kapsamında Genel Aydınlatma Metni ve Şirket Kişisel Verilerin Korunması ve İşlenmesi Yönetmeliği çerçevesinde,</div>
              <div className='mt-2'>Kişisel verilerinin veri sorumlusu sıfatıyla Şirket veya gerekli güvenlik tedbirlerini aldırmak suretiyle yetkilendirdiği veri işleyenler tarafından; Şirket’in müşterilerine sunmuş olduğu ürün ve hizmetlerini en iyi koşullar altında sağlayabilmesi, ürün veya hizmetlerin güvenilir ve kesintisiz bir şekilde temin edilmesi, müşteri memnuniyetinin en üst seviyeye çıkarılması, ödemelerin yapılması, mezkûr hizmetlere ilişkin çeşitli işlemlerin yerine getirilmesi, operasyonların yürütülmesi ve geliştirilmesi, mezkûr ürün ve hizmetlerin veya farklı ürün ve hizmetlerin tanıtım, pazarlama, reklam ve kampanya faaliyetlerinin yapılması, müşterilerin fırsatlardan, kampanyalardan ve sair hizmetlerden haberdar edilmesi ve müşterilerle akdedilen sözleşmelerin gereklerinin yerine getirilmesi amaçlarıyla doğrudan veya dolaylı olarak ilgili olan kimlik bilgilerinin, adres bilgilerinin, iletişim bilgilerinin ve sair kişisel verilerin; başta mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme ilkesi olmak üzere 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVK Kanunu”) 4. maddesinde ifade edilen genel ilkelere uygun şekilde işlenebileceğini; elde edilebileceğini, kaydedilebileceğini, işlenme amacıyla uygun süre zarfında fiziksel veya elektronik ortamda güvenli bir şekilde depolanabileceğini, muhafaza edilebileceğini, değiştirilebileceğini, yeniden düzenlenebileceğini, mevzuata uygun biçimde açıklanabileceğini ve aktarılabileceğini, devralınabileceğini, sınıflandırılabileceğini, işlenebileceğini ya da verilerin kullanılmasının engellenebileceğini; yukarıda belirtilen hususlarla ilgili olarak Şirket tarafından bilgilendirildiğimi ve KVK Kanunu çerçevesinde açık rızam bulunduğunu kabul ve beyan ederim.</div>
              <div className='mt-2'>İşbu kişisel verilerimin, yukarıda belirtilen amaçlarla bağlı kalmak kaydıyla, Şirket tarafından kanunen yetkili kamu kurum ve kuruluşlarına, faaliyetlerini yürütebilmek amacıyla, hukuki zorunluluklar ve yasal sınırlamalar çerçevesinde bağımsız denetim şirketlerine, Şirket’in hizmet aldığı veya birlikte çalıştığı iş ortaklarına ve hizmet sağlayıcılarına aktarılabileceğini ve bu hususta açık rızam olduğunu kabul ve beyan ederim.</div>
              <div className='mt-2'>Bununla birlikte, KVK Kanunu’nun 11.maddesi ve ilgili mevzuat uyarınca; Şirkete başvurarak kendimle ilgili; kişisel verilerimin işlenip işlenmediğini öğrenme, kişisel verilerim işlenmişse buna ilişkin bilgi talep etme, kişisel verilerimin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, işbu verilerin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerimin silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme taleplerinin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir sonucun ortaya çıkmasına itiraz etme, kişisel verilerimin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarımın olduğunu ve bu hakları kullanmak için kimliğimi tespit edici gerekli bilgiler ile kullanmayı talep ettiğim hakkıma yönelik açıklamaları da içeren talebimi şirkete iletme hakkına sahip olduğumu kabul ediyorum.</div>
              <div className='mt-2'>KVK Kanunu’nda tanımlanan özel nitelikli kişisel verilerim de dahil olmak üzere ilgili kişisel verilerimin işlenmesine, ilgili süreç kapsamında işlenme amacı ile sınırlı olmak üzere kullanılmasına ve paylaşılmasına, gereken süre zarfında saklanmasına açık rızam olduğunu ve bu hususta tarafıma gerekli aydınlatmanın yapıldığını; işbu metni ve Aydınlatma Metnini okuduğumu, siteye girişim ile bu bildirimi onaylamış olduğumu, gerekli KVKK bilgilendirme-işleme ve aydınlatma yükümlülüklerini açıkça kabul etmiş olduğumu. Sitede kaldığım sürece ve verdiğim bilgiler uyarınca gerekli bilgilendirme ve izinlerin yapılmış sayılacağını kabul ettiğimi kabul beyan ve taahhüt ederim. Aksi beyanda siteyi kullanmamam gerektiğinden dolayı doğacak zarar taleplerinden feragat etmiş ve şikayet haklarımın sona ermiş olacağını kabul etmekteyim.</div>
            </div>
        </Modal>
        
      </div>
    );
}

  export default Tablet1Register;