import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, BrowserRouter } from "react-router-dom"

import Home from "./components/Home"
import TestMe from "./components/TestMe"
import TestMe2 from "./components/TestMe2"
import Users from "./components/Users"
import Users_Hours from "./components/UsersHours"

import Tablet1Home from "./components/tablet1/Tablet1_home"
import Tablet1Register from "./components/tablet1/Tablet1_register"
import Tablet1Tutorial from "./components/tablet1/Tablet1_tutorial"
import Tablet1Wait from "./components/tablet1/Tablet1_wait"
import Tablet1Play from "./components/tablet1/Tablet1_play"
import Tablet1Finish from "./components/tablet1/Tablet1_finish"

import Tablet2Home from "./components/tablet2/Tablet2_home"
import Tablet2Register from "./components/tablet2/Tablet2_register"
import Tablet2Tutorial from "./components/tablet2/Tablet2_tutorial"
import Tablet2Wait from "./components/tablet2/Tablet2_wait"
import Tablet2Play from "./components/tablet2/Tablet2_play"
import Tablet2Finish from "./components/tablet2/Tablet2_finish"


import Ekran1 from "./components/ekran1/Ekran1"
import Ekran1Play from "./components/ekran1/Ekran1_play"
import Ekran1Finished from "./components/ekran1/Ekran1_finished"

import Ekran2 from "./components/ekran2/Ekran2"
import Ekran2Play from "./components/ekran2/Ekran2_play"
import Ekran2Finished from "./components/ekran2/Ekran2_finished"


import AnaEkran from "./components/ekranAna/AnaEkran"
import AnaEkranPlay from "./components/ekranAna/AnaEkran_play"
import AnaEkranFinished from "./components/ekranAna/AnaEkran_finished"



function App() {
  return (
    <div className="App">
        
    <BrowserRouter>
      <Routes>
        <Route  >

          <Route path="/" element={<Home />} />
          <Route path="/tablet1" element={<Tablet1Home />} />
          <Route path="/tablet1/register" element={<Tablet1Register />} />
          <Route path="/tablet1/tutorial" element={<Tablet1Tutorial />} />
          <Route path="/tablet1/wait" element={<Tablet1Wait />} />
          <Route path="/tablet1/play" element={<Tablet1Play />} />
          <Route path="/tablet1/finish" element={<Tablet1Finish />} />


          <Route path="/tablet2" element={<Tablet2Home />} />
          <Route path="/tablet2/register" element={<Tablet2Register />} />
          <Route path="/tablet2/tutorial" element={<Tablet2Tutorial />} />
          <Route path="/tablet2/wait" element={<Tablet2Wait />} />
          <Route path="/tablet2/play" element={<Tablet2Play />} />
          <Route path="/tablet2/finish" element={<Tablet2Finish />} />


          <Route path="/ekran1" element={<Ekran1 />} />
          <Route path="/ekran1/play" element={<Ekran1Play />} />
          <Route path="/ekran1/finished" element={<Ekran1Finished />} />

          <Route path="/ekran2" element={<Ekran2 />} />
          <Route path="/ekran2/play" element={<Ekran2Play />} />
          <Route path="/ekran2/finished" element={<Ekran2Finished />} />


          <Route path="/anaekran" element={<AnaEkran />} />
          <Route path="/anaekran/play" element={<AnaEkranPlay />} />
          <Route path="/anaekran/finished" element={<AnaEkranFinished />} />

          <Route path="/testme" element={<TestMe />} />
          <Route path="/testme2" element={<TestMe2 />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users_hours" element={<Users_Hours />} />



        </Route>
      </Routes>
    </BrowserRouter>
    <ToastContainer/>
  </div>
  );
}

export default App;
