
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue ,off} from "firebase/database";
import logo1 from '../../assets/images/logo1.png'

import {PropagateLoader} from 'react-spinners'

function Tablet2Wait() {

  const navigate = useNavigate();


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);



  const [cou, setcou] = useState(5);
  const [started, setstarted] = useState(false);
  const [user1_status, setuser1_status] = useState("");
  const [user2_status, setuser2_status] = useState("");

  useEffect(() => {

    get(child(dbRef, "user1_status")).then((snapshot) => { setuser1_status(snapshot.val()) })
    get(child(dbRef, "user2_status")).then((snapshot) => { setuser2_status(snapshot.val()) })

    const unsubscribe = onValue(ref(db, 'status'), (snapshot) => {

      
      const data = snapshot.val();

      if(data === "started"){
        setstarted(true)
      }else{
        setstarted(false)
      }

      
      
    });

    return () => {
      console.log("stoplled wait");
      unsubscribe()

    };



  },[]);

  useEffect(() => {
    
    if(user1_status === "ready" && user2_status === "ready"){
      set(ref(db, 'status' ),"started");
    }

  },[user2_status,user1_status]);






  /*
  useEffect(() => {
    if(started){

      const interval = setInterval(() => {
        setcou(cou -1);
      }, 1000);

      if(cou === 0){
        clearInterval(interval);
        set(ref(db, 'ekran_status' ),"started");
        navigate('/tablet2/play')
        
      }
      return () => clearInterval(interval);
    }

  }, [cou,started]);
  */



  const cikis = () => {

    set(ref(db, '/user2'),'');
    set(ref(db, '/user2_status'),'');
    set(ref(db, '/user2_puan'),0);

    localStorage.removeItem('user2_code');

    navigate('/tablet2')
  }



    return (
      <div className='py-10 px-28 relative'>
          <button onClick={cikis} className='absolute top-2 right-2 p-2 text-slate-300' >Çıkış</button>

        <img src={logo1} alt='' className='w-60 mt-10 m-auto ' />

              {started ?
              <div className='flex items-center flex-col mt-36'>
                <div  className='text-center font-bold text-3xl'>HAZIR OL, BAŞLIYORUZ!</div>
                <div className='mx-auto w-60 mt-10'>
                  <CountdownCircleTimer
                    isPlaying = {started}
                    size={240}
                    strokeWidth={24}
                    duration={5}
                    colors={'#00006B'}
                    onComplete={() => {
                      console.log("finished");
                      set(ref(db, 'ekran_status' ),"started");
                      navigate('/tablet2/play')
                    }}>
                    {({ remainingTime }) => {return <div className='text-8xl font-bold'>{remainingTime}</div>}}
                  </CountdownCircleTimer>
                </div>


              </div>
              :
              <div className='flex items-center flex-col mt-36'>
                <PropagateLoader color='#BF003D' />

                <div className='text-center font-bold text-2xl mt-14'>Diğer oyuncu bekleniyor...</div>                
              </div>
              }


      </div>
    );
}

  export default Tablet2Wait;