
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";


import logo1 from '../../assets/images/logo1.png'



function Tablet1Tutorial() {

  const navigate = useNavigate();


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);


  const [user_code, setuser_code] = useState("");



  useEffect(() => {
    
    const user_code = localStorage.getItem('user1_code');
    setuser_code(user_code)

  }, [])
  

  const hazirim = async () => {

    get(child(dbRef, "user2_status")).then((snapshot) => {
        
      const gelen = snapshot.val();

      if(gelen === "ready"){
        set(ref(db, '/status'),'started');
      }
      set(ref(db, '/user1_status'),'ready');
      navigate('/tablet1/wait')

    });



  }

  const cikis = () => {

    set(ref(db, '/user1'),'');
    set(ref(db, '/user1_status'),'');
    set(ref(db, '/user1_puan'),0);

    localStorage.removeItem('user1_code');

    navigate('/tablet1')
  }


  return (
    <div className='py-10 px-28 relative'>
                <button onClick={cikis} className='absolute top-2 right-2 p-2 text-slate-300' >Çıkış</button>

      <img src={logo1} alt='' className='w-56 mt-10 m-auto ' />

      <div className='text-3xl font-bold mt-20 mb-12 text-center'>NASIL OYNANIR?</div>


      <div className='mt-8 flex items-center space-x-2'>
        <div className='kirik_mini bg_mavi text-white px-10 py-2 text-2xl font-semibold relative'>1</div>
        <div className='font-semibold text-lg flex-1 z-10'>Desteklediğin sanatçıya yüksek sesle tezahürat yap.</div>
      </div>

      <div className='mt-8 flex items-center space-x-2'>
        <div className='kirik_mini bg_mavi text-white px-10 py-2 text-2xl font-semibold relative'>2</div>
        <div className='font-semibold text-lg flex-1 z-10'>30 saniye boyunca enerjini koru ve devam et.</div>
      </div>

      <div className='mt-8 flex items-center space-x-2'>
        <div className='kirik_mini bg_mavi text-white px-10 py-2 text-2xl font-semibold relative'>3</div>
        <div className='font-semibold text-lg flex-1 z-10'>Süre sonunda en yüksek yüzdeye ulaş ve kazan!</div>
      </div>
    

      <div className='mt-14'>
          <button className='bg_kirmizi p-4 w-full text-xl font-semibold text-white' onClick={hazirim}>HAZIRIM</button>  
          </div>
        
  </div>
  );
}

export default Tablet1Tutorial;