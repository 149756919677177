
import {  useState , useEffect} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";


import logo1 from '../../assets/images/logo1.png'
import vs from '../../assets/images/vs.png'
import waves from '../../assets/images/svg-animation-waves.svg'
import waves2 from '../../assets/images/svg-animation-waves_red.svg'
import tablet_cizgi_sol from '../../assets/images/katilimci_tv_cizgi_sol.png'


function AnaEkranPlay() {

  const navigate = useNavigate();

  const [cou, setcou] = useState(30);
  const [user_code, setuser_code] = useState("");
  const [user_name, setuser_name] = useState("");
  const [point, setPoint] = useState(0);

  const [user_name2, setuser_name2] = useState("");
  const [point2, setPoint2] = useState(0);



  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);


  useEffect(() => {

    get(child(dbRef, "user1")).then((snapshot) => {

      get(child(dbRef, "users/"+snapshot.val()+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          setuser_name(isim[0])
        } 
      })

    })

    get(child(dbRef, "user2")).then((snapshot) => {

      get(child(dbRef, "users/"+snapshot.val()+"/name")).then((snapshot) => {
        if (snapshot.exists()) {
          let isim= snapshot.val().split(" ");
          setuser_name2(isim[0])
        } 
      })

    })



    const unsubscribe1 = onValue(ref(db, 'ekran_status'), (snapshot) => {
      
      const data = snapshot.val();

      if(data === ""){ navigate('/anaekran')  }
      if(data === "finished"){ navigate('/anaekran/finished')  }
      
    });

    const unsubscribe2 = onValue(ref(db, 'user1_puan'), (snapshot) => {
      setPoint(snapshot.val())
    });
    const unsubscribe3 = onValue(ref(db, 'user2_puan'), (snapshot) => {
      setPoint2(snapshot.val())
    });


    return () => { unsubscribe1(); unsubscribe2(); unsubscribe3()};

  }, [])
  




  useEffect(() => {

      const interval = setInterval(() => {
        if(cou > 0){
          setcou(cou -1);
        }
      }, 1000);

      return () => clearInterval(interval);

  }, [cou]);


  return (
    <div className='p-10 h-screen relative'>
                  <img src={logo1} alt='' className='w-80 mt-10 m-auto ' />
                  

                  <img src={tablet_cizgi_sol} className='absolute z-20 ' width={33.2} style={{ top:470, left:510}} alt=''  />
                  <img src={tablet_cizgi_sol} className='absolute rotate-180 z-20 ' width={33.2} style={{top:470, left:540}} alt=''  />


                  
                  <div className='flex items-center space-x-6 justify-center mt-20 ' style={{top:460}}>
                    <div className=' text-white px-10 py-3 font-bold text-5xl bg_kirmizi'>{user_name2}</div>
                    <img src={vs} alt='' className='w-20 ' />
                    <div className=' text-white px-10 py-3 font-bold text-5xl bg_mavi'>{user_name}</div>
                  </div>



                  
                  <div className='bg-slate-300 absolute  bottom-56   rounded-3xl overflow-hidden' style={{left:80, width:400, top:460}}>
                    <img src={waves2} alt='' className='w-full absolute left-0 right-0 z-10 ' style={{bottom:(point2*13.3)-100}} />
                    <div className='bg_kirmizi w-full transition-all bottom-0 absolute ' style={{height:point2+'%'}}></div>
                    <div className='text-center absolute bottom-10 z-10 text-white font-bold text-9xl left-0 right-0'>%{Math.round(point2)}</div>
                  </div>

                  <div className='bg-slate-300 absolute  bottom-56   rounded-3xl overflow-hidden' style={{right:80, width:400, top:460}}>
                    <img src={waves} alt='' className='w-full absolute left-0 right-0 z-10 ' style={{bottom:(point*13.3)-100}} />
                    <div className='bg_mavi w-full transition-all bottom-0 absolute ' style={{height:point+'%'}}></div>
                    <div className='text-center absolute bottom-10 z-10 text-white font-bold text-9xl left-0 right-0'>%{Math.round(point)}</div>
                  </div>




                  <div className='absolute bottom-6 left-0 right-0'>

                    <div className='text-center font-bold text-4xl mt-10'>KALAN SÜRE</div>
                    <div className='text-center font-bold text-6xl mt-1 mb-10'>00:{Math.round(cou) < 10 ? "0":""}{Math.round(cou)}</div>
                  </div>




              
      </div>


  );
}

export default AnaEkranPlay;