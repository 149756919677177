
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";

import { CountdownCircleTimer } from 'react-countdown-circle-timer'



function UsersHours() {
  var AudioStreamMeter = require('audio-stream-meter');
  const navigate = useNavigate();
  const [user_code, setuser_code] = useState("");
  const [user_name, setuser_name] = useState("");


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [users, setusers] = useState([]);


  const [decibel, setDecibel] = useState(0);
  const [decibel_div, setdecibel_div] = useState(0);
  const [point_div, setpoint_div] = useState(0);


  const [cou, setcou] = useState(300);
  const [started, setstarted] = useState(false);


  const [users_15, setusers_15] = useState([]);
  const [users_16, setusers_16] = useState([]);
  const [users_17, setusers_17] = useState([]);
  const [users_18, setusers_18] = useState([]);
  const [users_19, setusers_19] = useState([]);
  const [users_20, setusers_20] = useState([]);
  const [users_21, setusers_21] = useState([]);
  const [users_22, setusers_22] = useState([]);
  const [users_23, setusers_23] = useState([]);
  const [users_24, setusers_24] = useState([]);


  useEffect(() => {


      getUsers()
    
  },[]);


  function compare( a, b ) {
    return a.puan - b.puan;
  }

  const getUsers = async () => {


    await get(child(dbRef, "users")).then((snapshot) => {
      
      //
      var _t_15 = []
      var _t_16 = []
      var _t_17 = []
      var _t_18 = []
      var _t_19 = []
      var _t_20 = []
      var _t_21 = []
      var _t_22 = []
      var _t_23 = []
      var _t_24 = []

      snapshot.forEach(e => {

        const dates = new Date(e.val().time_created);

        var pp = 0
        if(e.val().puan !== "" && e.val().puan !== undefined){
          pp = e.val().puan
        }

        const res = {
          name: e.val().name,
          email: e.val().email,
          phone: e.val().phone,
          puan: parseFloat(pp).toFixed(2),
          puan_ham: pp,
          sure: e.val().sure,
          time_created: e.val().time_created,
          saat: dates.getHours(),
          dakika: dates.getMinutes()
        }


        if(dates.getHours() <= 15 && dates.getHours() >= 8 ){_t_15.push(res)}
        if(dates.getHours() === 16){_t_16.push(res)}
        if(dates.getHours() === 17){_t_17.push(res)}
        if(dates.getHours() === 18){_t_18.push(res)}
        if(dates.getHours() === 19){_t_19.push(res)}
        if(dates.getHours() === 20){_t_20.push(res)}
        if(dates.getHours() === 21){_t_21.push(res)}
        if(dates.getHours() === 22){_t_22.push(res)}
        if(dates.getHours() === 23){_t_23.push(res)}
        if(dates.getHours() === 24){_t_24.push(res)}
        if(dates.getHours() >= 0 && dates.getHours() <= 7){_t_24.push(res)}
        
      });


      setusers_15(_t_15)
      setusers_16(_t_16)
      setusers_17(_t_17)
      setusers_18(_t_18)
      setusers_19(_t_19)
      setusers_20(_t_20)
      setusers_21(_t_21)
      setusers_22(_t_22)
      setusers_23(_t_23)
      setusers_24(_t_24)
      

    })
  }





    return (
      <div className='p-10 h-screen relative'>

        <div className='text-xl font-semibold'>16 Öncesi</div>
 
        <table className='table table-auto border w-full' border={1} >

          <thead>
            <tr>
              <th className='p-1 text-left'>#</th>
              <th className='p-1 text-left'>İsim</th>
              <th className='p-1 text-left'>Email</th>
              <th className='p-1 text-left'>Phone</th>
              <th className='p-1 text-left'>Puan</th>
              <th className='p-1 text-left'>Saat</th>
            </tr>
          </thead>

          <tbody>

          {users_15.sort((a, b) => b.puan - a.puan).map((user, index) => (
            <tr key={index} >
              <td className='p-1'>{index+1}</td>
              <td className='p-1'>{user.name}</td>
              <td className='p-1'>{user.email}</td>
              <td className='p-1'>{user.phone}</td>
              <td className='p-1 w-40'>{user.puan}</td>
              <td className='p-1'>{user.saat}: {user.dakika}</td>
            </tr>
          ))}

          </tbody>
        </table>

        <div className='text-xl font-semibold mt-10'>16-17</div>

        <table className='table table-auto border w-full' border={1} >

          <thead>
            <tr>
              <th className='p-1 text-left'>#</th>
              <th className='p-1 text-left'>İsim</th>
              <th className='p-1 text-left'>Email</th>
              <th className='p-1 text-left'>Phone</th>

              <th className='p-1 text-left'>Puan</th>
              <th className='p-1 text-left'>Saat</th>
            </tr>
          </thead>

          <tbody>

          {users_16.sort((a, b) => b.puan - a.puan).map((user, index) => (
            <tr key={index} >
              <td className='p-1'>{index+1}</td>
              <td className='p-1'>{user.name}</td>
              <td className='p-1'>{user.email}</td>
              <td className='p-1'>{user.phone}</td>
              <td className='p-1 w-40'>{user.puan}</td>
              <td className='p-1'>{user.saat}: {user.dakika}</td>
            </tr>
          ))}

          </tbody>
        </table>

        <div className='text-xl font-semibold mt-10'>17-18</div>

        <table className='table table-auto border w-full' border={1} >

          <thead>
            <tr>
              <th className='p-1 text-left'>#</th>
              <th className='p-1 text-left'>İsim</th>
              <th className='p-1 text-left'>Email</th>
              <th className='p-1 text-left'>Phone</th>

              <th className='p-1 text-left'>Puan</th>
              <th className='p-1 text-left'>Saat</th>
            </tr>
          </thead>

          <tbody>

          {users_17.sort((a, b) => b.puan - a.puan).map((user, index) => (
            <tr key={index} >
              <td className='p-1'>{index+1}</td>
              <td className='p-1'>{user.name}</td>
              <td className='p-1'>{user.email}</td>
              <td className='p-1'>{user.phone}</td>
              <td className='p-1 w-40'>{user.puan}</td>
              <td className='p-1'>{user.saat}: {user.dakika}</td>
            </tr>
          ))}

          </tbody>
        </table>





        <div className='text-xl font-semibold mt-10'>18-19</div>

        <table className='table table-auto border w-full' border={1} >

          <thead>
            <tr>
              <th className='p-1 text-left'>#</th>
              <th className='p-1 text-left'>İsim</th>
              <th className='p-1 text-left'>Email</th>
              <th className='p-1 text-left'>Phone</th>

              <th className='p-1 text-left'>Puan</th>
              <th className='p-1 text-left'>Saat</th>
            </tr>
          </thead>

          <tbody>

          {users_18.sort((a, b) => b.puan - a.puan).map((user, index) => (
            <tr key={index} >
              <td className='p-1'>{index+1}</td>
              <td className='p-1'>{user.name}</td>
              <td className='p-1'>{user.email}</td>
              <td className='p-1'>{user.phone}</td>
              <td className='p-1 w-40'>{user.puan}</td>
              <td className='p-1'>{user.saat}: {user.dakika}</td>
            </tr>
          ))}

          </tbody>
        </table>



        <div className='text-xl font-semibold mt-10'>19-20</div>

        <table className='table table-auto border w-full' border={1} >

          <thead>
            <tr>
              <th className='p-1 text-left'>#</th>
              <th className='p-1 text-left'>İsim</th>
              <th className='p-1 text-left'>Email</th>
              <th className='p-1 text-left'>Phone</th>

              <th className='p-1 text-left'>Puan</th>
              <th className='p-1 text-left'>Saat</th>
            </tr>
          </thead>

          <tbody>

          {users_19.sort((a, b) => b.puan - a.puan).map((user, index) => (
            <tr key={index} >
              <td className='p-1'>{index+1}</td>
              <td className='p-1'>{user.name}</td>
              <td className='p-1'>{user.email}</td>
              <td className='p-1'>{user.phone}</td>
              <td className='p-1 w-40'>{user.puan}</td>
              <td className='p-1'>{user.saat}: {user.dakika}</td>
            </tr>
          ))}

          </tbody>
        </table>



        <div className='text-xl font-semibold mt-10'>20-21</div>

        <table className='table table-auto border w-full' border={1} >

          <thead>
            <tr>
              <th className='p-1 text-left'>#</th>
              <th className='p-1 text-left'>İsim</th>
              <th className='p-1 text-left'>Email</th>
              <th className='p-1 text-left'>Phone</th>

              <th className='p-1 text-left'>Puan</th>
              <th className='p-1 text-left'>Saat</th>
            </tr>
          </thead>

          <tbody>

          {users_20.sort((a, b) => b.puan - a.puan).map((user, index) => (
            <tr key={index} >
              <td className='p-1'>{index+1}</td>
              <td className='p-1'>{user.name}</td>
              <td className='p-1'>{user.email}</td>
              <td className='p-1'>{user.phone}</td>
              <td className='p-1 w-40'>{user.puan}</td>
              <td className='p-1'>{user.saat}: {user.dakika}</td>
            </tr>
          ))}

          </tbody>
        </table>





        <div className='text-xl font-semibold mt-10'>21-22</div>

<table className='table table-auto border w-full' border={1} >

  <thead>
    <tr>
      <th className='p-1 text-left'>#</th>
      <th className='p-1 text-left'>İsim</th>
      <th className='p-1 text-left'>Email</th>
      <th className='p-1 text-left'>Phone</th>

      <th className='p-1 text-left'>Puan</th>
      <th className='p-1 text-left'>Saat</th>
    </tr>
  </thead>

  <tbody>

  {users_21.sort((a, b) => b.puan - a.puan).map((user, index) => (
    <tr key={index} >
      <td className='p-1'>{index+1}</td>
      <td className='p-1'>{user.name}</td>
      <td className='p-1'>{user.email}</td>
      <td className='p-1'>{user.phone}</td>
      <td className='p-1 w-40'>{user.puan}</td>
      <td className='p-1'>{user.saat}: {user.dakika}</td>
    </tr>
  ))}

  </tbody>
</table>



<div className='text-xl font-semibold mt-10'>22-23</div>

<table className='table table-auto border w-full' border={1} >

  <thead>
    <tr>
      <th className='p-1 text-left'>#</th>
      <th className='p-1 text-left'>İsim</th>
      <th className='p-1 text-left'>Email</th>
      <th className='p-1 text-left'>Phone</th>

      <th className='p-1 text-left'>Puan</th>
      <th className='p-1 text-left'>Saat</th>
    </tr>
  </thead>

  <tbody>

  {users_22.sort((a, b) => b.puan - a.puan).map((user, index) => (
    <tr key={index} >
      <td className='p-1'>{index+1}</td>
      <td className='p-1'>{user.name}</td>
      <td className='p-1'>{user.email}</td>
      <td className='p-1'>{user.phone}</td>
      <td className='p-1 w-40'>{user.puan}</td>
      <td className='p-1'>{user.saat}: {user.dakika}</td>
    </tr>
  ))}

  </tbody>
</table>



<div className='text-xl font-semibold mt-10'>23-24</div>

<table className='table table-auto border w-full' border={1} >

  <thead>
    <tr>
      <th className='p-1 text-left'>#</th>
      <th className='p-1 text-left'>İsim</th>
      <th className='p-1 text-left'>Email</th>
      <th className='p-1 text-left'>Phone</th>

      <th className='p-1 text-left'>Puan</th>
      <th className='p-1 text-left'>Saat</th>
    </tr>
  </thead>

  <tbody>

  {users_23.sort((a, b) => b.puan - a.puan).map((user, index) => (
    <tr key={index} >
      <td className='p-1'>{index+1}</td>
      <td className='p-1'>{user.name}</td>
      <td className='p-1'>{user.email}</td>
      <td className='p-1'>{user.phone}</td>
      <td className='p-1 w-40'>{user.puan}</td>
      <td className='p-1'>{user.saat}: {user.dakika}</td>
    </tr>
  ))}

  </tbody>
</table>



<div className='text-xl font-semibold mt-10'>24 Sonrası</div>

<table className='table table-auto border w-full' border={1} >

  <thead>
    <tr>
      <th className='p-1 text-left'>#</th>
      <th className='p-1 text-left'>İsim</th>
      <th className='p-1 text-left'>Email</th>
      <th className='p-1 text-left'>Phone</th>

      <th className='p-1 text-left'>Puan</th>
      <th className='p-1 text-left'>Saat</th>
    </tr>
  </thead>

  <tbody>

  {users_24.sort((a, b) => b.puan - a.puan).map((user, index) => (
    <tr key={index} >
      <td className='p-1'>{index+1}</td>
      <td className='p-1'>{user.name}</td>
      <td className='p-1'>{user.email}</td>
      <td className='p-1'>{user.phone}</td>
      <td className='p-1 w-40'>{user.puan}</td>
      <td className='p-1'>{user.saat}: {user.dakika}</td>
    </tr>
  ))}

  </tbody>
</table>



      </div>
    );
}

  export default UsersHours;