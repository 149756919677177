
import {  useState , useEffect, useRef} from 'react'

import {Routes, Route, useNavigate, redirect} from 'react-router-dom';

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child,set,onValue } from "firebase/database";
import logo1 from '../../assets/images/ipad_final_logo.png'

function Tablet1Finish() {

  const navigate = useNavigate();


  const config = {
    apiKey: "AIzaSyBCIv4GXl3uTtWkiCI1sq6WKjFu456VXSs",
    authDomain: "redbulsoundclash.firebaseapp.com",
    databaseURL: "https://redbulsoundclash-default-rtdb.firebaseio.com",
    projectId: "redbulsoundclash",
    storageBucket: "redbulsoundclash.appspot.com",
    messagingSenderId: "522736890307",
    appId: "1:522736890307:web:e7e665e256353a5fb00d76"
  };

  const f_app = initializeApp(config);
  const db = getDatabase(f_app);
  const dbRef = ref(db);

  const [user1, setuser1] = useState("");
  const [user1_puan, setuser1_puan] = useState("");
  const [user2, setuser2] = useState("");
  const [user2_puan, setuser2_puan] = useState("");
  const [kazanan, setKazanan] = useState("");
  const [kazanan_renk, setkazanan_renk] = useState("");

  useEffect(() => {

    get(child(dbRef, "user1")).then((snapshot) => { setuser1(snapshot.val()) })
    get(child(dbRef, "user1_puan")).then((snapshot) => { setuser1_puan(snapshot.val())})
    get(child(dbRef, "user2")).then((snapshot) => { setuser2(snapshot.val())})
    get(child(dbRef, "user2_puan")).then((snapshot) => { setuser2_puan(snapshot.val())})

    counmet4puanlar()
    counmet()

    const unsubscribe = onValue(ref(db, 'status'), (snapshot) => {

      const data = snapshot.val();

      if(data === ""){
        gohome()
      }

    });

    return () => { unsubscribe()};


  }, [])

  const counmet4puanlar = async () => {
    setTimeout(function () {
      get(child(dbRef, "user1")).then((snapshot) => { setuser1(snapshot.val()) })
      get(child(dbRef, "user1_puan")).then((snapshot) => { setuser1_puan(snapshot.val())})
      get(child(dbRef, "user2")).then((snapshot) => { setuser2(snapshot.val())})
      get(child(dbRef, "user2_puan")).then((snapshot) => { setuser2_puan(snapshot.val())})
    }, 1000);
  }


  
  const counmet = async () => {
    setTimeout(function () {
      set(ref(db, '/status'),'');
      set(ref(db, '/ekran_status'),"");
    }, 15000);
  }









  useEffect(() => {

    if(user1 !== "" && user1_puan !== "" && user2 !== "" && user2_puan !== ""){
      
        if(user1_puan > user2_puan){
          get(child(dbRef, "users/"+user1)).then((snapshot) => { 
            let isim= snapshot.val().name.split(" ");
            setKazanan(isim[0])
            setkazanan_renk("mavi")
          })
        }

        else if(user1_puan < user2_puan){
          get(child(dbRef, "users/"+user2)).then((snapshot) => { 
            let isim= snapshot.val().name.split(" ");
            setKazanan(isim[0])
            setkazanan_renk("kirmizi")

          })
        }

        else{
          setKazanan("Berabere")
          setkazanan_renk("mavi")

        }

    }

  }, [user1,user1_puan,user2_puan,user2])


  const gohome = () => {

      set(ref(db, '/user1'),'');
      set(ref(db, '/user1_status'),'');
      set(ref(db, '/user1_puan'),0);
      
      set(ref(db, '/user2'),'');
      set(ref(db, '/user2_status'),'');
      set(ref(db, '/user2_puan'),0);
      
      

      localStorage.removeItem('user1_code');
      localStorage.removeItem("user2_code");

      navigate('/tablet1')
    
  }

    
  const cikis = () => {

    set(ref(db, '/status'),'');
    set(ref(db, '/ekran_status'),"");

    gohome()

  }



    return (
      <div className="ipad_bg_final p-10 h-screen  flex flex-col items-center justify-center  relative ">
          <button onClick={cikis} className='absolute top-2 right-2 p-2 text-slate-300' >Çıkış</button>
          <img src={logo1} alt='' className='w-128 mx-auto ' />
          <div  className='text-center font-bold text-4xl mt-12'>OYUN SONA ERDİ.</div>
          <div  className='text-center font-bold text-3xl mt-8'>KAZANAN</div>
          <span className={(kazanan_renk === "mavi" ? "bg_mavi" : "bg_kirmizi" ) + ' text-center font-bold text-4xl mt-2  text-white px-6 py-2'}>{kazanan}</span>
      </div>
    );
}

  export default Tablet1Finish;