
import {  useState , useEffect} from 'react'
import { Link } from 'react-router-dom';

function Home() {

  

  return (
    <>
    <div className='flex space-x-4 p-10'>
      <Link to={"/tablet1"}>
        <div className='bg_mavi rounded-md px-6 py-2 text-white'>TABLET 1</div>
      </Link>
      <Link to={"/tablet2"}>
        <div className='bg_kirmizi rounded-md px-6 py-2 text-white'>TABLET 2</div>
      </Link>

      
    </div>
    <div className='mt-10'>Sürüm: 2.7 - User Saat List, Beltur kaldırıldı </div>
    </>

  );
}

export default Home;